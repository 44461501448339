<template>
  <div class="page page-recovery-password2">
    <Header/>
    <DialogCard :title="$t('Change password')" :description="$t('Please check INBOX/SPAM to get recovery code. After, enter recovery code and new password to change password')">
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Recovery code") }}<i class="required"></i></label>
            <b-form-input v-model="formData.secretKey" :placeholder="$t('Enter a recovery code')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }" vid="password" :name="$t('New password')">
            <label class="form-control-label">{{ $t("New password") }}<i class="required"></i></label>
            <div class="position-relative">
              <b-form-input style="padding-right: 45px !important" :type="showPass ? 'text' : 'password'" v-model="formData.password" :placeholder="$t('Enter a password')" :readonly="loading" autocomplete="off"/>
              <a href="#" class="position-absolute" style="top: 15px; right: 15px" @click.prevent="showPass = !showPass"><i :class="['fa-solid', showPass ? 'fa-eye-slash' : 'fa-eye']"></i></a>
            </div>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Confirm new password") }}<i class="required"></i></label>
            <b-form-input type="password" v-model="formData.confirm" :placeholder="$t('Enter a password')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group class="mb-0">
          <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="loading">
            {{$t("Save changes")}}
          </b-button>
        </b-form-group>
      </ValidationObserver>
    </DialogCard>
    <LoadingModal :show="loading"/>
  </div>
</template>

<script>
import Header from "@/components/headers/Header";
import DialogCard from "@/components/cards/DialogCard";
import dialogHelper from "@/helpers/dialogHelper";
import authenticateService from "@/services/authenticateService";

export default {
  name: "ChangePassword",
  components: {Header, DialogCard},
  data() {
    return {
      loading: false,
      showPass: false,
      formData: {
        username: "",
        secretKey: "",
        publicKey: "",
        password: "",
        confirm: ""
      }
    }
  },
  created() {
    let data = this.$store.getters.recoveryPassword;
    if (!data) {
      const route = this.$route;
      data = {
        code: route.query.code,
        username: route.query.username,
      }
    }
    
    if (!data || !data.code || data.code.length < 1 || !data.username || data.username.length < 1)
      this.$router.push({name: "RecoveryPassword"});
    
    this.formData.username = data.username;
    this.formData.publicKey = data.code;
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await authenticateService.changePassword(this.formData, await this.$recaptcha('changePasswordByCode'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      await this.$store.dispatch("setRecoveryPassword", null);
      await this.$router.push({name: "Login"})
    }
  }
}
</script>

<style scoped>

</style>